import './navigation-drawer'
import './navigation-dropdown'
import './testimonial'
import './cookies'
/* eslint-disable no-undef */
// import Swiper from 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.mjs'

const rectangleBottomBorderTransformBusiness = `<svg
class="transformBusiness__rectangle-bottom"
width="100%"
height="auto"
viewBox="0 0 1440 54"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M1440 1L0 53" stroke="#E2E8F0" />
</svg>`

document.querySelector('.transformBusiness').innerHTML += rectangleBottomBorderTransformBusiness

const rectangleBottomTestimonial = `<svg class="testimonial__bg-rectangle-bottom" width="100%" height="100%" viewBox="0 0 1440 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_2239_14618" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="52">
  <path d="M1440 52L0 51.9999L1440 0L1440 52Z" fill="#1E2432" />
</mask>
<g mask="url(#mask0_2239_14618)">
  <rect x="-9" width="1458" height="879" fill="white" />
</g>
</svg>`

document.querySelector('.section__testimonial').innerHTML += rectangleBottomTestimonial

const arrowUpRightIcon = `<svg
width="20"
height="20"
viewBox="0 0 20 20"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M5.8335 14.1666L14.1668 5.83325M14.1668 5.83325H5.8335M14.1668 5.83325V14.1666"
  stroke="#05A6F0"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
/>
</svg>`

document.querySelectorAll('.btn-talk-us').forEach((el) => {
  el.innerHTML += arrowUpRightIcon
  el.setAttribute('onclick', "location.href = 'contact-us.html';")
})
document.querySelectorAll('.btn-cta-product').forEach(el => {
  el.innerHTML += arrowUpRightIcon
})
document.querySelectorAll('.btn-product-growth').forEach(el => {
  el.innerHTML += arrowUpRightIcon
})
document.querySelector('.btn-product-testimonial').innerHTML += arrowUpRightIcon
document.querySelector('.btn-product-about-us').innerHTML += arrowUpRightIcon
document.querySelector('.btn-see-all-product').innerHTML += arrowUpRightIcon
// document.querySelector('.btn-learn-more').innerHTML += arrowUpRightIcon
document.querySelector('.btn-book-demo__home').innerHTML += arrowUpRightIcon
document.querySelectorAll('.information-map__contact-btn').forEach((el) => {
  el.innerHTML += arrowUpRightIcon
})

const preHeadingServiceIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#1DCA5D" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8332 14.2284C13.2709 13.8584 14.3332 12.5533 14.3332 11.0001C14.3332 9.15913 12.8408 7.66675 10.9998 7.66675C9.15889 7.66675 7.6665 9.15913 7.6665 11.0001C7.6665 12.5533 8.72881 13.8584 10.1665 14.2284V23.5001C10.1665 23.9603 10.5396 24.3334 10.9998 24.3334C11.4601 24.3334 11.8332 23.9603 11.8332 23.5001V14.2284Z" fill="white" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6665 21.0001C17.6665 19.4469 18.7288 18.1418 20.1665 17.7718V12.6667C20.1665 12.4457 20.0787 12.2338 19.9224 12.0775C19.7661 11.9212 19.5542 11.8334 19.3332 11.8334H16.8332C16.3729 11.8334 15.9998 11.4603 15.9998 11.0001C15.9998 10.5398 16.3729 10.1667 16.8332 10.1667H19.3332C19.9962 10.1667 20.6321 10.4301 21.1009 10.899C21.5698 11.3678 21.8332 12.0037 21.8332 12.6667V17.7718C23.2709 18.1418 24.3332 19.4469 24.3332 21.0001C24.3332 22.841 22.8408 24.3334 20.9998 24.3334C19.1589 24.3334 17.6665 22.841 17.6665 21.0001Z" fill="white" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.6667 21.0001C17.6667 19.4469 18.729 18.1418 20.1667 17.7718V12.6667C20.1667 12.4457 20.0789 12.2338 19.9226 12.0775C19.7663 11.9212 19.5543 11.8334 19.3333 11.8334H16.8333C16.3731 11.8334 16 11.4603 16 11.0001C16 10.5398 16.3731 10.1667 16.8333 10.1667H19.3333C19.9964 10.1667 20.6323 10.4301 21.1011 10.899C21.5699 11.3678 21.8333 12.0037 21.8333 12.6667V17.7718C23.271 18.1418 24.3333 19.4469 24.3333 21.0001C24.3333 22.841 22.8409 24.3334 21 24.3334C19.1591 24.3334 17.6667 22.841 17.6667 21.0001Z" fill="#77DF9E" />
</svg>`

document.querySelectorAll('.services__preHeading .services__icon').forEach((el) => {
  el.innerHTML += preHeadingServiceIcon
})
document.querySelector('.transformBusiness__preHeading-icon').innerHTML += preHeadingServiceIcon
document.querySelector('.product__preHeading .services__icon').innerHTML += preHeadingServiceIcon
document.querySelector('.testimonial-slider-preHeading .services__icon').innerHTML += preHeadingServiceIcon
document.querySelector('.seamless-integration__preHeading .preHeadingIcon').innerHTML += preHeadingServiceIcon

const servicesListItemIcon = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6668 3.5L5.25016 9.91667L2.3335 7" stroke="#1E2432" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

document.querySelectorAll('.services__list-item-icon').forEach(el => {
  el.innerHTML += servicesListItemIcon
})

const customerStoryContentBg = `<svg width="100%" height="100%" viewBox="0 0 996 237" fill="none" class="customer-story__content-bg large" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
  <path d="M0 51.5277C0 45.0877 5.08356 39.7958 11.5185 39.5374L996 0V237H0V51.5277Z" fill="#1E2432"/>
</svg>`
const customStoryContentBgMobile = `<svg width="100%" height="100%" viewBox="0 0 327 316" fill="none" class="customer-story__content-bg small" xmlns="http://www.w3.org/2000/svg">
<path d="M0 19.801L327 0V316H0V19.801Z" fill="#1E2432"/>
</svg>
`

document.querySelector('.js-customer-story__content-bg').innerHTML += customerStoryContentBg
document.querySelector('.js-customer-story__content-bg').innerHTML += customStoryContentBgMobile

const customerStoryContentQuote = `<svg class="customer-story-content-quote" width="129" height="106" viewBox="0 0 129 106" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M31.5528 100.408L33.082 101.603C39.642 96.4754 45.6415 87.5784 50.1383 77.3158C54.6425 67.0361 57.6861 55.2798 58.2597 44.3428C58.8321 33.4304 56.9513 23.1835 51.4305 16.0776C45.8535 8.89921 36.7219 5.12449 23.291 6.87312C9.97101 8.60731 -1.20758 22.1227 1.90096 35.9014C3.42636 42.6629 6.73531 47.897 11.486 51.2858C16.1257 54.5953 22.053 56.0827 28.8403 55.6095C31.3989 58.2953 34.8558 62.8601 36.3839 69.829C37.976 77.0894 37.4988 87.0709 31.5528 100.408ZM86.1274 99.006L87.4636 100.416C94.7392 96.2706 102.223 88.0993 108.521 78.2625C114.831 68.4075 120.017 56.7779 122.639 45.6107C125.253 34.4726 125.353 23.637 121.282 15.4907C117.16 7.24188 108.883 1.97667 95.3218 1.8041C81.8971 1.63326 68.7638 13.4199 69.695 27.4942C70.1522 34.4039 72.6092 40.0582 76.7811 44.0904C80.8527 48.0255 86.477 50.3378 93.2507 50.8332C97.3181 56.9567 101.697 74.7873 86.1274 99.006Z" fill="#F3A515" stroke="white" stroke-width="2"/>
</svg>`

document.querySelector('.js-customer-story-content-quote').innerHTML += customerStoryContentQuote

const chevronDownIcon = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 4.5L6 7.5L9 4.5" stroke="#94A3B8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

document.querySelectorAll('.link-item .arrow-down').forEach((el) => {
  el.innerHTML += chevronDownIcon
})

const hamburgerIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12H21M3 6H21M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

document.querySelector('.btn-hamburger').innerHTML += hamburgerIcon

const rectangleBottomProductGrowth = `<svg width="100%" height="auto" viewBox="0 0 1440 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1440 52L0 51.9999L1440 0L1440 52Z" fill="white"/>
</svg>`

document.querySelector('.rectangleBottonProductGrow').innerHTML += rectangleBottomProductGrowth

const rectangleTopFinancialExpert = `<svg width="100%" height="auto" viewBox="0 0 1440 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0H1440L0 52V0Z" fill="white"/>
</svg>
`
document.querySelector('.financial-feature-section__rectangle-top').innerHTML += rectangleTopFinancialExpert

const featureSectionEl = document.querySelector('.feature-section')
featureSectionEl.querySelectorAll('.tab-feature').forEach(tabElement => {
  tabElement.addEventListener('click', () => {
    featureSectionEl.querySelectorAll('.tab-feature').forEach(el => {
      el.classList.remove('active')
    })

    featureSectionEl.querySelectorAll('.features__illustration img').forEach(el => {
      el.style.display = 'none'

      if (tabElement.getAttribute('data-tab') === el.getAttribute('data-tab')) {
        el.style.display = 'block'
      }
    })

    featureSectionEl.querySelectorAll('.feature-points').forEach(el => {
      el.style.display = 'none'

      if (tabElement.getAttribute('data-tab') === el.getAttribute('data-tab')) {
        el.style.display = 'grid'
      }
    })

    console.log(tabElement.getAttribute('data-tab'))

    tabElement.classList.add('active')
  })
})

const featurePointsDigitalTransformation = featureSectionEl.querySelector('.feature-points[data-tab="digital-transformation"]').cloneNode(true)
featureSectionEl.querySelector('.select-group-feature[data-tab="digital-transformation"]').querySelector('.feature-points-mobile').appendChild(featurePointsDigitalTransformation)

const featurePointsIntelligentFinance = featureSectionEl.querySelector('.feature-points[data-tab="intelligent-finance"]').cloneNode(true)
featureSectionEl.querySelector('.select-group-feature[data-tab="intelligent-finance"]').querySelector('.feature-points-mobile').appendChild(featurePointsIntelligentFinance)

const featurePointsPeopleManagement = featureSectionEl.querySelector('.feature-points[data-tab="people-management"]').cloneNode(true)
featureSectionEl.querySelector('.select-group-feature[data-tab="people-management"]').querySelector('.feature-points-mobile').appendChild(featurePointsPeopleManagement)

const featurePointsAnalytics = featureSectionEl.querySelector('.feature-points[data-tab="analytics"]').cloneNode(true)
featureSectionEl.querySelector('.select-group-feature[data-tab="analytics"]').querySelector('.feature-points-mobile').appendChild(featurePointsAnalytics)

featureSectionEl.querySelectorAll('.select-group-feature').forEach(selectElement => {
  selectElement.addEventListener('click', () => {
    if (selectElement.classList.contains('active')) {
      selectElement.classList.remove('active')

      selectElement.querySelector('.select-feature-content').style.display = 'none'
      selectElement.querySelector('.feature-points').style.display = 'none'
    } else {
      featureSectionEl.querySelectorAll('.select-group-feature').forEach(el => {
        el.querySelector('.select-feature-content').style.display = 'none'
        el.classList.remove('active')
      })

      selectElement.classList.add('active')

      selectElement.querySelector('.select-feature-content').style.display = 'block'
      selectElement.querySelector('.feature-points').style.display = 'grid'
    }
  })
})

// Create the observer like the examples above
const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('animate__fadeInUp')
    }

    // entry.target.classList.remove('animate__animated')
  })
})

// Get multiple elements instead of a single one using "querySelectorAll"
const squares = document.querySelectorAll('.animated-js')

// Loop over the elements and add each one to the observer
squares.forEach((element) => observer.observe(element))
// eslint-disable-next-line no-unused-vars
const swiper = new Swiper('.swiper-testimonial', {
  // Optional parameters
  // direction: 'vertical',
  loop: false,
  centeredSlides: true,
  slidesPerView: 1,
  spaceBetween: 32,

  // If we need pagination
  // pagination: {
  //   el: '.swiper-pagination'
  // },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-testimonial-next-button',
    prevEl: '.swiper-testimonial-prev-button'
  },

  // And if we need scrollbar
  // scrollbar: {
  //   el: '.swiper-scrollbar'
  // }
  breakpoints: {
    1200: {
      slidesPerView: 1.2
    }
  }
})

// eslint-disable-next-line no-unused-vars
const swiperSeamlessIntegration = new Swiper('.swiperSeamlessIntegration', {
  loop: true,
  spaceBetween: 300,

  navigation: {
    nextEl: '.swiper-seamless-integration-button-next',
    prevEl: '.swiper-seamless-integration-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
})

// eslint-disable-next-line no-unused-vars
const swiperClientLogos = new Swiper('.swiper-client-logos', {
  loop: true,
  autoplay: true
  // spaceBetween: 300
})

const isLarge = window.matchMedia('(min-width: 1200px)')

if (isLarge.matches) {
  // Then trigger an alert
  swiper.on('slideChange', function () {
    if (swiper.activeIndex === 0) {
      document.querySelector('.swiper-wrapper.testimonial-cards').style.left = '-8.25%'
    } else if (swiper.activeIndex === 1) {
      document.querySelector('.swiper-wrapper.testimonial-cards').style.left = 'auto'
      document.querySelector('.swiper-wrapper.testimonial-cards').style.right = 'auto'
    } else if (swiper.activeIndex === 2) {
      document.querySelector('.swiper-wrapper.testimonial-cards').style.right = '-8.25%'
    }
  })
}
