const testimonials = [
  {
    name: 'Neo Christodoulou',
    photo: {
      mobile: new URL('../image/testimoni-neo-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimoni-neo.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimoni-neo.png?as=webp', import.meta.url)
    },
    position: 'Founder, Smashing Plates',
    companyLogo: new URL(
      '../image/smashing-plates.png?as=webp',
      import.meta.url
    ),
    companyLogoClass: 'smashing-plates',
    testimonyText: 'Wenodo have been a long-standing close partner. Their holistic approach in managing our accounts has been invaluable to our organization. Not only do they handle all of our accounting needs, but they also expertly manage our HR/payroll department and also their customized cloud-based real-time analytics and reports has provided us with valuable insights and data-driven decision-making.'
  },
  {
    name: 'Johnny Smith and Daniel Willis',
    photo: {
      mobile: new URL('../image/testimoni-johny-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimoni-johny.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimoni-johny.png?as=webp', import.meta.url)
    },
    position: 'Co Founder, Luca Restaurant',
    companyLogo: new URL(
      '../image/luca-company.webp?as=webp',
      import.meta.url
    ),
    companyLogoClass: 'luca',
    testimonyText: "Wenodo's team is exceptional! Their profound knowledge of the hospitality industry and their friendly customer service have been invaluable to our business. Their expertise in the intricacies of our industry coupled with their approachable and supportive service have made working with them a true pleasure. They understand our needs and offer tailored solutions, making a significant difference in our operations."
  },
  {
    name: 'Wonchul Park',
    photo: {
      mobile: new URL('../image/testimonial-wonchul-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimonial-wonchul.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimonial-wonchul.png?as=webp', import.meta.url)
    },
    position: 'Michelin Star Chef, Sollip',
    companyLogo: new URL(
      '../image/sollip-company.png',
      import.meta.url
    ),
    companyLogoClass: 'sollip',
    testimonyText: 'Wenodo have been a long-standing close partner. Their holistic approach in managing our accounts has been invaluable to our organization. Not only do they handle all of our accounting needs, but they also expertly manage our HR/payroll department and also their customized cloud-based real-time analytics and reports has provided us with valuable insights and data-driven decision-making.'
  },
  {
    name: 'George Kakavitsa',
    photo: {
      mobile: new URL('../image/testimonial-george-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimonial-george.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimonial-george.png?as=webp', import.meta.url)
    },
    position: 'Assistant Restaurant Manager, Lío London',
    companyLogo: new URL(
      '../image/lio-company.png',
      import.meta.url
    ),
    companyLogoClass: 'lio',
    testimonyText: "Wenodo's HR/Rota SaaS solution has revolutionized our workforce management. Its intuitive interface simplifies employee's lifecycle, shift scheduling and communication. With robust reporting, we gain valuable insights for informed decisions. Exceptional customer support ensures smooth operations. It's a game-changer for our Restaurant operation, enhancing efficiency and employee engagement. Highly recommended!"
  },
  {
    name: 'Dominic Hamdy',
    photo: {
      mobile: new URL('../image/testimonial-dominic-hamdy-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimonial-dominic-hamdy.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimonial-dominic-hamdy.png?as=webp', import.meta.url)
    },
    position: 'Founder, HAM Restaurants',
    companyLogo: new URL(
      '../image/ham-company.png',
      import.meta.url
    ),
    companyLogoClass: 'ham',
    testimonyText: "WENODO has helped to grow and streamline our business from day one. We can now make financial and strategic decisions based on data we can trust. Their personal attitude and enthusiasm to go the extra mile is invaluable, but it's their modern approach to accounting that has  propelled us forward. "
  },
  // {
  //   name: 'Tarek Farah',
  //   photo: {
  //     mobile: new URL('../image/testimonial-tarek-farah-mobile.png?as=webp', import.meta.url),
  //     tablet: new URL('../image/testimonial-tarek-farah.png?as=webp', import.meta.url),
  //     desktop: new URL('../image/testimonial-tarek-farah.png?as=webp', import.meta.url)
  //   },
  //   position: 'Founder and CEO, Aline Lebanese Kitchen',
  //   companyLogo: new URL(
  //     '../image/aline-company.png',
  //     import.meta.url
  //   ),
  //   companyLogoClass: 'aline',
  //   testimonyText: 'I have been gladly working with the Wenodo team for nearly 10 years. They are not just professional, experienced, and knowledgeable accountants but also great consultants who offer so much value and advice on all levels of hospitality. I cannot imagine any of our future projects being developed without Wenodo by our side!'
  // },
  {
    name: 'David Bellaiche',
    photo: {
      mobile: new URL('../image/testimonial-david-bellaiche-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimonial-david-bellaiche.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimonial-david-bellaiche.png?as=webp', import.meta.url)
    },
    position: 'Co-Founder, NAC London',
    companyLogo: new URL(
      '../image/nac-company.png',
      import.meta.url
    ),
    companyLogoClass: 'nac',
    testimonyText: "Wenodo's daily, weekly, and monthly KPIs and analytics has been so critical for our business. Their insightful Dashboard and reports have allowed us to maximize profits. With these detailed metrics, we're empowered to make timely and informed decisions, contributing significantly to our success."
  },
  {
    name: 'Gabriel Cohen-Elia',
    photo: {
      mobile: new URL('../image/testimonial-gabriel-mobile.png?as=webp', import.meta.url),
      tablet: new URL('../image/testimonial-gabriel.png?as=webp', import.meta.url),
      desktop: new URL('../image/testimonial-gabriel.png?as=webp', import.meta.url)
    },
    position: 'Co-Founder, Crème London',
    companyLogo: new URL(
      '../image/creme-company.png',
      import.meta.url
    ),
    companyLogoClass: 'creme',
    testimonyText: "Partnering with Wenodo has revolutionized how we manage our finances and operations. Their specialized accounting and bookkeeping solutions have not only streamlined our processes but also brought unparalleled clarity to our financial landscape. Wenodo's dedicated support team is always responsive and knowledgeable, making them an integral part of our success. For anyone in hospitality seeking reliability and expertise, Wenodo is the clear choice."
  }
]

export default testimonials
